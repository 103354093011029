import React from 'react';

export function LessSugar() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5214 23.3164V4"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M28.6759 16.5L35.505 23.329L42.3341 16.5"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <rect
        x="10.1038"
        y="11.2082"
        width="11.3394"
        height="11.3394"
        transform="rotate(13.2575 10.1038 11.2082)"
        stroke="#2C3650"
        strokeWidth="2.5"
      />
      <rect
        x="28.4187"
        y="27.2759"
        width="11.3394"
        height="11.3394"
        transform="rotate(55.6085 28.4187 27.2759)"
        stroke="#2C3650"
        strokeWidth="2.5"
      />
    </svg>
  );
}
