import React from 'react';

export function AntiInflammatory() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12_11952)">
        <path
          d="M9.02045 12.2998L14.3069 8.48247C14.3069 8.48247 15.7749 9.2884 17.2394 8.17504C17.2394 8.17504 18.6112 7.33179 18.4901 5.45776L23.6802 1.60501C23.6802 1.60501 26.7173 7.82964 20.3555 12.4235C20.3555 12.4235 14.7661 16.7038 9.02045 12.2998Z"
          fill="#C2DA96"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M25.5355 26.6517C25.6956 27.0741 27.0994 27.0575 28.5201 26.9237C29.9408 26.7899 31.3784 26.5388 31.6059 26.4924C32.0658 26.3985 32.6613 26.2603 33.1239 26.0258C33.5864 25.7913 33.9159 25.4605 33.8435 24.9813C33.7692 24.4892 33.3564 24.3129 32.8166 24.1897C32.2768 24.0665 31.6099 23.9965 31.0274 23.7172C29.6858 23.0737 30.2127 22.6527 31.2062 22.38C32.1997 22.1073 33.6598 21.9827 34.1846 21.9322C35.5534 21.8003 37.4508 21.2838 39.1236 20.587C40.7964 19.8902 42.2445 19.013 42.7148 18.16C43.3268 17.0499 42.728 16.8447 41.9032 16.6996C41.0784 16.5544 40.0276 16.4691 39.7354 15.5988C39.5551 15.0618 41.8221 13.6664 43.9058 12.0871C45.9895 10.5078 47.89 8.74457 46.9767 7.47197C46.545 6.87046 45.6802 7.08383 44.8188 7.50092C43.9573 7.918 43.0992 8.53879 42.6808 8.7521C42.1467 9.02445 41.2061 9.66709 40.4328 9.99957C39.6595 10.3321 39.0534 10.3544 39.1882 9.38609C39.2851 8.69024 40.2416 7.46564 40.8818 6.26133C41.5221 5.05701 41.8463 3.87299 40.6785 3.2583C39.8776 2.83669 38.9838 3.5794 38.206 4.55111C37.4281 5.52281 36.7663 6.72351 36.4295 7.21788C35.5537 8.50317 34.8602 9.91734 34.1717 11.3277C33.4831 12.7381 32.7993 14.1448 31.9428 15.415C31.5545 15.991 30.9919 16.3472 30.5684 16.3323C30.1449 16.3174 29.8603 15.9314 30.028 15.0229C30.176 14.2206 30.565 13.1208 30.7401 12.1871C30.9153 11.2534 30.8765 10.486 30.169 10.3487C29.2425 10.1688 28.6657 11.0234 28.3012 12.0594C27.9368 13.0953 27.7848 14.3125 27.708 14.8578C27.5417 16.0377 27.3819 17.2158 27.2125 18.3929C27.0432 19.5699 26.8644 20.7457 26.6603 21.9211C26.5893 22.3299 26.2383 23.3288 25.94 24.3174C25.6417 25.306 25.396 26.2843 25.5355 26.6517Z"
          fill="#438442"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M22 31.6066C23.7977 29.9373 24.8927 28.0525 25.9454 26.2223C27.3379 23.8019 28.6529 21.5155 31.4618 20.3177C35.688 18.5155 38.1738 13.5311 38.8348 12.0467"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M35.2369 30.0194C35.2369 30.0194 41.8071 32.4428 43.8738 25.3083C43.8738 25.3083 36.9449 22.7852 35.2369 30.0194Z"
          fill="#C2DA96"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M36 29.5L32 31.3505"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M15.7822 21.0619C14.9399 22.0063 15.5423 21.3311 14.6999 22.2755C15.0339 23.1919 15.0819 23.4525 15.1358 23.9428C15.3879 26.2368 13.3681 27.6619 12.393 28.2034C11.0997 28.9217 9.71227 28.23 9.41663 28.0826C9.12741 27.9384 8.03211 27.3923 7.62937 26.1045C7.18029 24.6681 7.85683 23.8104 8.02367 23.3052C7.60019 23.1542 7.21891 23.0184 6.79544 22.8674C6.50891 23.1983 6.007 24.1367 5.78348 24.9466C5.19778 27.0697 6.57955 28.4913 6.72283 28.6816C8.09613 30.5057 10.603 31.096 12.4808 30.4746C13.994 29.9738 14.9001 28.7527 15.1304 28.4422C15.315 28.1933 15.7281 27.394 16.554 25.7952C17.0305 24.8727 17.2827 24.3571 17.8564 23.7646C17.9693 23.648 18.3479 23.1864 19.0167 22.8248C19.7237 22.4427 20.2211 22.3702 20.8097 21.9777C21.848 21.2854 22.2394 20.558 22.5127 19.521C22.7276 18.7057 22.7773 17.142 21.9138 15.8218C21.2054 16.1523 20.8184 16.1952 20.0916 16.5032C20.2588 16.7748 20.4222 17.1465 20.4291 17.7789C20.4433 19.078 19.7555 19.9913 19.639 20.1259C18.8865 20.9951 17.826 21.2253 17.3117 21.2762C16.7241 21.3343 16.094 21.1347 15.7822 21.0619Z"
          fill="#D2451E"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M44.6825 34.0004H2.65337L2.27011 34.0004C2.21052 33.9936 1.98006 34.0783 2.00139 34.3223C2.02958 34.6448 3.16985 39.429 8.06879 42.7441C8.06879 42.7441 14.1383 47.0157 22.4925 47C30.8466 46.9842 35.3539 45.6906 39.2619 43.2363C43.1699 40.7821 45.3849 36.8915 45.9241 34.7824C45.9241 34.7824 46.0745 34.2369 45.9519 34.1344C45.8586 34.0565 45.8056 34.0252 45.5614 34.0004H44.6825Z"
          fill="#93BDDA"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_11952">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
