import React from 'react';

export function DairyFree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.332 38.0938L18.0681 29.9028"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M4.3797 23.5C4.3797 12.9402 12.9402 4.3797 23.5 4.3797C28.4855 4.3797 33.0253 6.28776 36.429 9.41345C40.2348 12.9084 42.6203 17.9256 42.6203 23.5C42.6203 34.0599 34.0599 42.6203 23.5 42.6203C12.9402 42.6203 4.3797 34.0599 4.3797 23.5Z"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M20.3516 11.1683C20.8158 14.9605 18.7357 19.8713 18.7357 19.8713C18.0162 22.0239 18.0749 23.5087 18.0749 23.5087V36.725C18.606 37.9192 19.802 37.8677 19.802 37.8677L26.6458 37.8686C28.0915 37.8952 28.8957 36.725 28.8957 36.725V24.8645C29.1058 22.137 28.1672 19.6366 28.1672 19.6366C26.4606 16.1964 26.6189 11.1683 26.6189 11.1683"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M29 11.5H18"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M27.7153 18.4729L35.668 8.92786"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path d="M28.5 24.5H18.5" stroke="#2C3650" strokeWidth="2.5" />
    </svg>
  );
}
