import React from 'react';

export function GlutenFree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11_11756)">
        <path
          d="M26.4575 30.1845C26.4575 30.1845 28.7025 31.0066 29.9346 30.0507C31.1667 29.0949 31.7813 27.0479 33.724 25.9115C35.6666 24.7752 37.4287 22.9284 39.3376 22.2545C41.2464 21.5805 43.0001 19.8493 43.0677 18.9245C43.1354 17.9997 41.8947 16.0646 41.8947 16.0646"
          fill="#EDAB93"
        />
        <path
          d="M26.4575 30.1845C26.4575 30.1845 28.7025 31.0066 29.9346 30.0507C31.1667 29.0949 31.7813 27.0479 33.724 25.9115C35.6666 24.7752 37.4287 22.9284 39.3376 22.2545C41.2464 21.5805 43.0001 19.8493 43.0677 18.9245C43.1354 17.9997 41.8947 16.0646 41.8947 16.0646"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M38.3017 13.5501C37.2322 13.9444 36.0499 15.4329 34.8859 16.0003C34.0511 16.4071 33.2291 16.8399 32.39 17.2377C29.6789 18.5232 22.82 23.697 23.1253 27.204C23.1429 27.4065 23.2963 28.9037 24.4981 29.72C24.6928 29.8522 25.5031 30.366 26.4575 30.1844C27.3483 30.0149 27.8383 29.3397 28.7056 28.2077C30.0924 26.3978 30.7857 25.4928 31.2751 24.957C32.8665 23.2146 34.7158 22.3626 35.7634 21.88C37.0532 21.2857 38.3732 20.8906 40.1158 19.7474C40.4962 19.4979 41.063 19.1036 41.4727 18.3796C41.5881 18.1756 42.1433 17.1943 41.8947 16.0645C41.5145 14.3368 39.4449 13.1287 38.3017 13.5501Z"
          fill="#EDAB93"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M18.3387 23.6106C18.3387 23.6106 16.1551 24.8012 14.7197 22.3508C14.7197 22.3508 13.4091 20.0874 12.1001 19.882C11.2637 19.7507 10.0336 19.3041 9.79706 18.267C9.79706 18.267 9.24133 16.8715 10.1371 15.2619C10.1371 15.2619 11.2752 13.3631 10.4904 11.7166C10.4904 11.7166 9.45987 9.61987 10.4874 8.0992C10.4874 8.0992 11.8436 6.1933 14.1499 7.95571C14.1499 7.95571 16.5024 10.0999 19.0108 9.22278C19.0108 9.22278 21.1751 8.28409 21.4855 11.5743C21.4855 11.5743 21.8235 13.754 23.7299 14.5531C23.7299 14.5531 25.7096 15.2498 24.3875 18.0678C24.3875 18.0678 23.7343 19.3829 21.9194 19.288C21.9194 19.288 20.2707 18.8904 19.7857 21.2456C19.7857 21.2456 19.3803 23.1196 18.3387 23.6106Z"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M16.4393 18.5855C15.2547 18.5115 14.1367 17.7287 13.7157 16.6361C13.6125 16.3684 13.4939 15.9333 13.5452 15.4074C13.6296 14.5417 14.1542 13.6386 15.0631 13.1892C16.1309 12.6612 17.1531 13.0445 17.3729 13.1338C18.497 13.5907 19.1702 14.7075 19.2317 15.7916C19.2453 16.0312 19.311 17.1876 18.4374 17.9765C17.6774 18.6629 16.7246 18.6033 16.4393 18.5855Z"
          fill="#F6B60B"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M3.66618 20.4066C3.45826 20.8723 3.73113 21.3973 3.81477 21.5583C7.67557 28.9874 13.5531 29.1648 13.5531 29.1648C14.1507 29.3207 17.5009 29.2164 18.8894 29.7603C19.5185 30.0068 20.1068 30.4041 20.3885 30.7443C20.4282 30.7922 20.5954 31.144 20.6883 31.1484C20.7817 31.1528 20.9233 31.0636 20.9171 30.9149C20.9081 30.7009 20.167 29.7464 18.9506 28.914C18.2406 28.4282 17.5592 28.1413 16.1962 27.5674C15.9117 27.4476 14.9067 27.0292 13.4958 26.5552C12.1783 26.1125 12.2691 26.2135 11.5733 25.9453C10.76 25.6317 9.64844 25.1928 8.57273 24.2659C7.96344 23.741 7.54491 23.214 7.23353 22.8219C6.71839 22.1733 6.18513 21.5385 5.67904 20.8831C5.60921 20.7927 5.25963 20.3394 4.66204 20.0955C4.32231 19.9568 4.1326 19.9797 4.00006 20.0405C3.7901 20.1369 3.69553 20.341 3.66618 20.4066Z"
          fill="#A6BE78"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M4.33919 20.4165C4.33919 20.4165 2.86 18.9088 2.07679 17.0982"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M24.0242 4.41489C24.0267 4.3132 24.0292 4.20613 24.0318 4.09957C24.0379 3.85635 24.3242 3.7935 24.4465 4.0038C26.2323 7.07363 30.3142 11.6906 35.3091 10.5038C35.5297 10.4514 35.7236 10.687 35.6033 10.8791C35.1188 11.6524 34.1305 12.9334 32.6125 13.2965C31.1755 13.6402 29.9759 13.1292 29.28 12.8329C26.0405 11.4531 23.9393 7.7514 24.0242 4.41489Z"
          fill="#E5D1E5"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M37.1408 19.3221L34.2622 18.3469"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M33.7117 21.4011L30.5764 20.066"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M30.7619 23.5846L28.1389 22.8706"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M27.9822 26.2211L26.0266 25.5724"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M35.9559 28.1253C36.9762 28.0857 37.6426 28.733 37.7837 28.8777C38.0106 29.1105 38.5266 29.6398 38.4031 30.2516C38.2949 30.7884 37.7579 31.0757 37.5669 31.1779C36.5367 31.7292 34.9329 31.484 34.3211 30.4559C33.5348 29.1341 34.8041 28.17 35.9559 28.1253Z"
          fill="#D69E25"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M42.1909 28.2755C39.6304 25.3277 42.5725 24.8306 43.8261 26.2056C45.3312 27.8562 45.8212 32.5844 42.1909 28.2755Z"
          fill="#D69E25"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M44.6825 34.0004H2.65337L2.27011 34.0004C2.21052 33.9936 1.98006 34.0783 2.00139 34.3223C2.02958 34.6448 3.16985 39.429 8.06879 42.7441C8.06879 42.7441 14.1383 47.0157 22.4925 47C30.8466 46.9842 35.3539 45.6906 39.2619 43.2363C43.1699 40.7821 45.3849 36.8915 45.9241 34.7824C45.9241 34.7824 46.0745 34.2369 45.9519 34.1344C45.8586 34.0565 45.8056 34.0252 45.5614 34.0004H44.6825Z"
          fill="#93BDDA"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_11756">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
