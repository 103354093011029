import React from 'react';

export function DairyFree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.845 25.7838C42.6026 24.6794 42.2845 23.9265 42.9093 22.8928C43.3959 22.0878 44.5265 21.9749 44.7223 20.9457C45.0357 19.2978 41.0599 20.5217 39.9913 20.9953"
        fill="#F9EED8"
      />
      <path
        d="M42.845 25.7838C42.6026 24.6794 42.2845 23.9265 42.9093 22.8928C43.3959 22.0878 44.5265 21.9749 44.7223 20.9457C45.0357 19.2978 41.0599 20.5217 39.9913 20.9953"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M36.9069 21.1186C37.0827 20.6198 37.9724 20.4858 38.5191 20.5535C39.1278 20.6289 39.6843 20.8763 40.0603 21.2355C40.5355 21.6895 40.7361 22.393 41.0407 22.9281C41.3963 23.553 41.7726 24.1704 42.1691 24.7798C42.4755 25.2507 43.204 25.8905 42.828 26.4568C42.6339 26.7491 42.2148 26.9582 41.8174 27.0628C41.3155 27.1949 40.5323 27.1834 40.2544 26.8291C39.7711 26.2132 39.4228 25.5054 39.0152 24.8565C38.5471 24.1113 38.079 23.3662 37.6108 22.621C37.3785 22.2512 36.7482 21.568 36.9069 21.1186Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M34.1782 21.9653C34.354 21.4665 35.2437 21.3324 35.7904 21.4002C36.3991 21.4756 36.9061 21.6829 37.203 22.0838C38.1495 23.3619 38.95 24.8687 39.3465 25.478C39.653 25.949 40.4516 26.8541 40.0993 27.3034C39.8783 27.5854 39.4861 27.8049 39.0887 27.9095C38.5868 28.0416 37.8036 28.03 37.5257 27.6758C37.0424 27.0599 35.3503 24.2128 34.8821 23.4676C34.6498 23.0979 34.0195 22.4146 34.1782 21.9653Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M31.4586 22.7828C31.6345 22.2839 32.5242 22.1499 33.0708 22.2176C33.6796 22.293 34.1866 22.5004 34.4835 22.9013C35.4299 24.1794 36.2305 25.6861 36.627 26.2954C36.9334 26.7664 37.7321 27.6715 37.3798 28.1209C37.1588 28.4028 36.7665 28.6224 36.3692 28.7269C35.8672 28.859 35.0841 28.8475 34.8061 28.4932C34.3229 27.8773 32.6307 25.0303 32.1626 24.2851C31.9303 23.9153 31.2999 23.2321 31.4586 22.7828Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M28.7455 23.6698C28.9213 23.171 29.811 23.037 30.3577 23.1047C30.9664 23.1801 31.4734 23.3875 31.7703 23.7884C32.7168 25.0665 33.5173 26.5732 33.9138 27.1825C34.2203 27.6535 35.0189 28.5586 34.6667 29.008C34.4456 29.2899 34.0534 29.5094 33.6561 29.614C33.1541 29.7461 32.3709 29.7346 32.093 29.3803C31.6098 28.7644 29.9176 25.9174 29.4494 25.1722C29.2171 24.8024 28.5868 24.1192 28.7455 23.6698Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M26.1948 25.3078C26.4695 26.4046 26.8096 27.1478 26.2153 28.1995C25.7526 29.0185 24.6257 29.1645 24.4602 30.199C24.1952 31.8554 28.1334 30.5152 29.1877 30.0104"
        fill="#F9EED8"
      />
      <path
        d="M26.1948 25.3078C26.4695 26.4046 26.8096 27.1478 26.2153 28.1995C25.7526 29.0185 24.6257 29.1645 24.4602 30.199C24.1952 31.8554 28.1334 30.5152 29.1877 30.0104"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M25.8401 24.3C26.016 23.8011 26.9057 23.6671 27.4524 23.7348C28.0611 23.8102 28.5681 24.0176 28.865 24.4185C29.8115 25.6966 30.612 27.2033 31.0085 27.8127C31.3149 28.2836 32.1136 29.1887 31.7613 29.6381C31.5403 29.92 31.148 30.1396 30.7507 30.2441C30.2487 30.3763 29.4656 30.3647 29.1877 30.0104C28.7044 29.3946 27.0123 26.5475 26.5441 25.8023C26.3118 25.4325 25.6815 24.7493 25.8401 24.3Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M34.0924 12.1782C34.0275 12.4559 33.9274 12.8331 33.7743 13.2686C33.6035 13.7544 33.3341 14.52 32.7856 15.3146C32.0534 16.375 30.8666 17.5563 29.5903 17.2019C29.516 17.1813 28.5483 16.894 28.3559 15.9306C28.327 15.7852 28.2463 15.4095 28.4101 15.0306C28.6452 14.4869 29.1441 14.3 29.8873 14.0743C31.4804 13.7412 32.2075 12.8581 32.2966 12.7311C32.5635 12.3507 32.7009 11.9817 32.7726 11.7423C33.2125 11.8876 33.6524 12.0329 34.0924 12.1782Z"
        fill="#F0CA9E"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M29.2964 10.3588C33.5266 11.6906 36.6452 12.6484 37.7707 13.0379C38.7019 13.3602 39.7384 13.2733 40.5426 12.6371C41.9806 10.9204 41.2962 7.63505 39.5501 6.535C38.8138 6.07111 37.9243 5.97566 37.1944 5.48342C36.125 4.76217 35.48 3.71893 34.223 3.22327C33.7566 3.03937 32.975 2.73244 32.0511 2.94482C31.4152 3.09103 30.7675 3.47067 30.4608 3.71109C29.6993 4.30852 28.8607 4.72051 28.3156 5.57842C27.8586 6.29768 27.6054 7.37 27.9071 8.43798C28.2322 9.58847 28.4716 10.0103 29.2964 10.3588Z"
        fill="#F0CA9E"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10.3598 29.1746C10.3598 29.1746 22.0834 32.1385 23.1996 20.5337C23.1996 20.5337 12.3474 17.3711 10.3598 29.1746Z"
        fill="#C2DA96"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M22.3969 8.6825C21.7017 7.79157 21.0898 7.25032 21.2075 6.04799C21.2991 5.1117 22.2703 4.52093 22.0029 3.50827C21.5746 1.88684 18.5161 4.70999 17.7565 5.59921"
        fill="#F9EED8"
      />
      <path
        d="M22.3969 8.6825C21.7017 7.79157 21.0898 7.25031 21.2075 6.04799C21.2991 5.1117 22.2703 4.52093 22.0029 3.50827C21.5746 1.88684 18.5161 4.70999 17.7565 5.59921"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M8.00001 30.7516L23.2809 20.5294"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M15.0271 7.04444C14.9705 6.51862 15.7153 6.01295 16.2376 5.83757C16.8193 5.64226 17.4281 5.62471 17.9222 5.78591C18.5469 5.98968 19.0314 6.53728 19.537 6.88804C20.1274 7.29762 20.7333 7.69158 21.3539 8.06951C21.8335 8.3616 22.7668 8.62339 22.6719 9.29657C22.6229 9.64411 22.335 10.014 22.0217 10.2801C21.6259 10.6163 20.9144 10.9446 20.5108 10.7454C19.8092 10.3991 19.1896 9.9116 18.5419 9.50283C17.798 9.0334 17.0542 8.56396 16.3104 8.09454C15.9413 7.86161 15.0778 7.5182 15.0271 7.04444Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7.17108 15.4547C7.89217 16.3248 8.51963 16.8479 8.43733 18.0531C8.37323 18.9917 7.41969 19.6107 7.71676 20.6151C8.19242 22.2232 11.1668 19.3115 11.9001 18.4004"
        fill="#F9EED8"
      />
      <path
        d="M7.17108 15.4547C7.89217 16.3248 8.51963 16.8479 8.43733 18.0531C8.37323 18.9917 7.41969 19.6107 7.71676 20.6151C8.19242 22.2232 11.1668 19.3115 11.9001 18.4004"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12.9308 8.98809C12.8742 8.46227 13.619 7.9566 14.1414 7.78122C14.7231 7.58591 15.2699 7.55361 15.7107 7.78668C17.116 8.52971 18.4883 9.54201 19.1089 9.91993C19.5885 10.212 20.6995 10.6827 20.5756 11.2402C20.4979 11.59 20.2388 11.9576 19.9255 12.2238C19.5296 12.56 18.8182 12.8883 18.4146 12.6891C17.7129 12.3428 14.9579 10.5076 14.2141 10.0382C13.845 9.80525 12.9816 9.46185 12.9308 8.98809Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10.8302 10.9014C10.7736 10.3755 11.5184 9.86987 12.0408 9.69448C12.6225 9.49918 13.1693 9.46688 13.6101 9.69995C15.0154 10.443 16.3877 11.4553 17.0083 11.8332C17.4879 12.1253 18.5989 12.5959 18.475 13.1535C18.3973 13.5033 18.1381 13.8709 17.8248 14.137C17.429 14.4733 16.7175 14.8016 16.3139 14.6024C15.6123 14.256 12.8573 12.4209 12.1135 11.9515C11.7444 11.7185 10.881 11.3751 10.8302 10.9014Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M8.76545 12.8746C8.70887 12.3488 9.45364 11.8431 9.976 11.6678C10.5577 11.4724 11.1045 11.4401 11.5453 11.6732C12.9506 12.4162 14.3229 13.4285 14.9435 13.8065C15.4231 14.0986 16.5342 14.5692 16.4103 15.1268C16.3325 15.4766 16.0734 15.8442 15.7601 16.1103C15.3642 16.4465 14.6528 16.7748 14.2492 16.5756C13.5475 16.2293 10.7926 14.3941 10.0487 13.9247C9.67962 13.6918 8.81619 13.3484 8.76545 12.8746Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.41636 14.6993C6.35978 14.1735 7.10455 13.6678 7.62691 13.4925C8.20861 13.2972 8.75543 13.2649 9.19624 13.4979C10.6015 14.241 11.9738 15.2532 12.5944 15.6312C13.0741 15.9233 14.1851 16.3939 14.0612 16.9515C13.9834 17.3013 13.7243 17.6689 13.411 17.935C13.0152 18.2712 12.3037 18.5995 11.9001 18.4003C11.1984 18.054 8.44348 16.2189 7.69964 15.7494C7.33053 15.5165 6.4671 15.1731 6.41636 14.6993Z"
        fill="#F9EED8"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M44.6825 34.0004H2.65337L2.27011 34.0004C2.21052 33.9936 1.98006 34.0783 2.00139 34.3223C2.02958 34.6448 3.16985 39.429 8.06879 42.7441C8.06879 42.7441 14.1383 47.0157 22.4925 47C30.8466 46.9842 35.3539 45.6906 39.2619 43.2363C43.1699 40.7821 45.3849 36.8915 45.9241 34.7824C45.9241 34.7824 46.0745 34.2369 45.9519 34.1344C45.8586 34.0565 45.8056 34.0252 45.5614 34.0004H44.6825Z"
        fill="#93BDDA"
        stroke="#2C3650"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
