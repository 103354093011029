import React from 'react';

export function AntiInflammatory() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1667 35.8333C27.9112 35.8333 35 28.7445 35 20C35 11.2555 27.9112 4.16666 19.1667 4.16666C10.4222 4.16666 3.33334 11.2555 3.33334 20C3.33334 28.7445 10.4222 35.8333 19.1667 35.8333Z"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14.1435 23.3691C14.7608 26.5 16.4534 28 19.5 28C22.5466 28 25 25.9599 25 22.7627C25 20.2906 23.629 19.3245 22.0386 17.6555C20.0847 15.605 21.39 12 21.39 12C18.3434 12 13.0628 17.8883 14.1435 23.3691Z"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M22 16L29.1667 7.91666"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M9.17349 31.5L15.1735 25"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
