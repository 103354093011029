import React from 'react';

export function AntiInflammatory() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 43C33.4934 43 42 34.4934 42 24C42 13.5066 33.4934 5 23 5C12.5066 5 4 13.5066 4 24C4 34.4934 12.5066 43 23 43Z"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14.2478 29.4748C15.3141 34.5624 18.2377 37 23.5 37C28.7623 37 33 33.6848 33 28.4894C33 24.4722 30.6319 22.9023 27.8848 20.1902C24.5099 16.8581 26.7645 11 26.7645 11C21.5022 11 12.3812 20.5686 14.2478 29.4748Z"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M32.1493 32.3839C29.8619 32.2544 26.909 30.3776 26.1515 27.6889C25.0485 23.7739 20.0465 29.9892 21.3832 36.5779"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M27 19L35 9.5"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M12 39L16.2009 34"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
