import React from 'react';

export function Protein() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.0292 23.7434C33.2708 23.3652 32.1376 22.8366 30.7186 22.3125C28.7481 21.5848 26.8243 20.8907 24.2743 20.7539C23.3567 20.7046 21.9782 20.6411 20.2561 21.0604C17.861 21.6434 16.4873 22.7499 16.2126 22.9768C16.0513 23.1101 15.922 23.2269 15.8336 23.309C15.8006 23.3456 15.702 23.4456 15.5394 23.4788C15.3387 23.52 15.1834 23.4304 15.1472 23.4081C15.118 23.1726 15.0997 22.9411 15.0912 22.7141C14.95 18.9442 16.9417 13.3629 19.2512 14.1748C22.0029 15.1422 23.4349 15.3262 23.9161 15.4212C27.7792 16.1838 25.9028 13.5427 27.9793 13.2301C30.4334 12.8608 29.8837 7.31814 28.9367 6.51972C26.5807 4.53333 22.6409 5.40232 19.8325 6.29075C10.4887 9.24653 7.75001 22.6806 7.39949 24.5204C7.27657 24.6682 5.5717 26.7854 6.3443 29.1125C6.94237 30.9138 8.64619 31.6999 9.37225 32.0348C10.4055 32.5116 11.351 32.6179 11.9414 32.6378C13.8055 33.6807 17.1608 35.219 21.5759 35.4674C23.2612 35.5622 26.9165 35.7257 30.9809 33.8903C32.2403 33.3216 33.2284 32.7011 33.9171 32.2204"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M27.5 13.3333C25.4167 13.4722 22.5 12.9167 22.5 10.4167"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
