import React from 'react';

export function GlutenFree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.3797 23.5C4.3797 12.9402 12.9402 4.3797 23.5 4.3797C28.4855 4.3797 33.0253 6.28776 36.429 9.41345C40.2348 12.9084 42.6203 17.9256 42.6203 23.5C42.6203 34.0599 34.0599 42.6203 23.5 42.6203C12.9402 42.6203 4.3797 34.0599 4.3797 23.5Z"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17.1454 32.36C12.7054 32.36 9.84937 28.928 9.84937 23.6C9.84937 18.296 12.7054 14.84 17.1454 14.84C20.6014 14.84 23.2174 17.144 23.6734 20.528L20.7214 21.008C20.3134 18.848 19.0414 17.6 17.1454 17.6C14.4574 17.6 12.8494 19.88 12.8494 23.6C12.8494 27.32 14.4574 29.6 17.1454 29.6C19.4254 29.6 20.7694 28.184 20.7694 25.856H17.3614V23.312H23.6254V25.4C23.6254 29.624 21.0814 32.36 17.1454 32.36ZM26.525 32V15.2H37.085V17.96H29.525V22.712H36.533V25.376H29.525V32H26.525Z"
        fill="#2C3650"
      />
    </svg>
  );
}
