import React from 'react';

export function DairyFree() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.76797 33.4711L14.9859 25.9103"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M2.35049 20C2.35049 10.2524 10.2524 2.35049 20 2.35049C24.602 2.35049 28.7926 4.11178 31.9345 6.99703C35.4475 10.2231 37.6495 14.8544 37.6495 20C37.6495 29.7476 29.7476 37.6495 20 37.6495C10.2524 37.6495 2.35049 29.7476 2.35049 20Z"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14.9922 20.008V32.2077C15.4824 33.31 16.5865 33.2625 16.5865 33.2625L22.9038 33.2634C24.2383 33.2879 24.9806 32.2077 24.9806 32.2077V21.2595C25.1746 18.7419 24.3081 16.4338 24.3081 16.4338C22.7329 13.2582 22.879 8.61688 22.879 8.61688H17.0938C17.5222 12.1174 15.6022 16.6505 15.6022 16.6505C14.938 18.6375 14.9922 20.008 14.9922 20.008Z"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M25.0769 8.46155H14.9231"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M25.1538 20H15"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M23.8911 15.3596L31.232 6.5488"
        stroke="#2C3650"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
