import React from 'react';

export function LessSugar() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11_11834)">
        <path
          d="M11.7928 11.2291L17.9017 8.94876C17.9017 8.94876 19.1037 10.1148 20.8108 9.4292C20.8108 9.4292 22.357 8.97952 22.7367 7.14038L28.762 4.80031C28.762 4.80031 30.0416 11.6072 22.69 14.3514C22.69 14.3514 16.1664 16.998 11.7928 11.2291Z"
          fill="#C2DA96"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.6175 23.837C11.6411 23.0319 12.3393 23.6077 11.3629 22.8026C10.4601 23.1719 10.2016 23.23 9.71373 23.3028C7.43121 23.6437 5.9289 21.6807 5.3499 20.7273C4.582 19.4628 5.2194 18.0497 5.35522 17.7485C5.4881 17.4539 5.9913 16.3383 7.26257 15.8859C8.68039 15.3814 9.56374 16.0242 10.0751 16.1713C10.2094 15.7423 10.3304 15.3561 10.4648 14.927C10.1231 14.6536 9.16597 14.1884 8.34794 13.9965C6.2038 13.4936 4.83683 14.9294 4.65221 15.08C2.88272 16.523 2.39009 19.0508 3.08387 20.9032C3.64296 22.3958 4.89832 23.2539 5.21744 23.472C5.47331 23.6468 6.28806 24.0285 7.91765 24.7918C8.85795 25.2322 9.38299 25.4642 9.99728 26.0144C10.1181 26.1228 10.5941 26.4832 10.9814 27.1375C11.3907 27.8291 11.4824 28.3233 11.8974 28.8962C12.6294 29.907 13.3714 30.2698 14.4183 30.5027C15.2413 30.6858 16.8057 30.6748 18.0914 29.7608C17.7337 29.0657 17.6758 28.6807 17.3399 27.9664C17.075 28.1441 16.7099 28.3217 16.0783 28.3531C14.7807 28.4177 13.8414 27.7658 13.7024 27.6546C12.8046 26.9364 12.5335 25.8856 12.4627 25.3737C12.3818 24.7888 12.5568 24.1514 12.6175 23.837Z"
          fill="#D2451E"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M38.9062 8.38557C38.6887 8.35518 35.9054 8.00908 33.4419 9.71346C31.189 11.272 30.2336 13.833 29.9399 14.7771C29.3371 16.7147 29.605 18.0027 28.485 18.8065C27.4909 19.5201 26.458 19.0946 26.1639 19.6926C26.1125 19.7972 26.0969 19.9215 26.1011 20.0552C24.2169 20.7589 21.3012 21.8344 21.272 21.7637C20.9558 20.9981 20.0884 20.63 19.3344 20.9414C18.5805 21.2528 18.2256 22.1256 18.5418 22.8912C18.7485 23.3918 18.7522 23.4516 19.367 23.9259C19.2235 24.3898 19.2957 24.7168 19.5024 25.2173C19.8186 25.9829 20.7139 26.4109 21.4399 26.0396C22.4662 25.515 22.2094 24.0998 22.2326 24.0897C22.2406 24.0863 25.4692 22.7345 27.2856 21.9741C27.6133 22.2576 27.9848 22.5072 28.3855 22.6766C33.2535 24.7347 39.9106 22.9661 42.3134 20.7495C42.8859 20.2215 45.6272 17.6929 45.1949 14.2804C44.7961 11.1318 41.8944 8.80191 38.9062 8.38557Z"
          stroke="#323E5A"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M28.485 18.8065C29.605 18.0027 29.3371 16.7147 29.9399 14.777C30.2336 13.833 31.189 11.272 33.4419 9.71341C35.9054 8.00908 38.6887 8.35522 38.9062 8.38551C41.8944 8.80195 44.7961 11.1318 45.195 14.2805C45.6272 17.6929 42.8859 20.2215 42.3135 20.7495C39.9106 22.966 33.2535 24.7347 28.3855 22.6765C26.9337 22.0627 25.7962 20.4403 26.1639 19.6926C26.458 19.0946 27.4908 19.52 28.485 18.8065Z"
          fill="#D69E25"
          stroke="#323E5A"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M37.0549 27.2445C38.0621 27.0769 38.8046 27.6352 38.9628 27.761C39.2172 27.9634 39.7956 28.4235 39.7502 29.046C39.7103 29.5921 39.2138 29.9448 39.0371 30.0702C38.0845 30.7467 36.4626 30.7052 35.7263 29.7623C34.7799 28.5499 35.9178 27.4338 37.0549 27.2445Z"
          fill="#F0CA9E"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M27.5042 29.4885C24.5932 26.8862 27.4494 26.0229 28.866 27.2292C30.5668 28.6774 31.6479 33.3063 27.5042 29.4885Z"
          fill="#F0CA9E"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M44.6825 34.0004H2.65337L2.27011 34.0004C2.21052 33.9936 1.98006 34.0783 2.00139 34.3223C2.02958 34.6448 3.16985 39.429 8.06879 42.7441C8.06879 42.7441 14.1383 47.0157 22.4925 47C30.8466 46.9842 35.3539 45.6906 39.2619 43.2363C43.1699 40.7821 45.3849 36.8915 45.9241 34.7824C45.9241 34.7824 46.0745 34.2369 45.9519 34.1344C45.8586 34.0565 45.8056 34.0252 45.5614 34.0004H44.6825Z"
          fill="#93BDDA"
          stroke="#2C3650"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_11834">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
